.container {
  padding: 0;
  color: #00395B;
}

.centercontent {
  margin: auto;
  padding: 100px 0px 100px 0px;
  max-width: 350px;
}

.centercontent img {
  max-width: 75%;
  height: 100%;
  border: 0px solid #eaeaea;
  border-radius: 8px;
  width: 100%;
  margin: auto;
  display: block;
  padding-bottom: 12px;
}

.main {
  margin: 0 2rem 0 2rem;
  padding: 0 0 10px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  color: #00395B;
  min-height: 100vh;
}

.header {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  border-bottom: 0px solid #00395B;
  justify-content: center;
  align-items: center;
  color: #00395B;
}

.norecords {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  border-bottom: 0px solid #00395B;
  justify-content: center;
  align-items: center;
  color: #00395B;
}

.footer {
  padding: 0.1rem 0;
  background: #00395B;
  color: #FFFFFF;
  font-size: 16px;
  justify-content: center;
  align-items: center;
}
.footer h5 {
  display: block;
  text-align: center;
}
.footer ul {
  padding: 0.1rem;
  text-align: center;
}
.footer ul li {
  list-style-type: none;
  display: inline-block;
  margin: 0.1rem 0.75rem;
}
.footer ul a {
  text-decoration: none;
}
.footer ul a:hover {
  text-decoration: underline;
}



.title a {
  color: #00395B;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  color: #00395B;
  text-decoration: none;
}

.title {
  text-align: center;
  max-width: 410px;
  width: 90%;
  margin: auto;
  display: block;
  padding: 20px 0px 15px 0px;
}

.description {
  text-align: center;
}

.terms {
  font-size: 14px;
  color: #00395B;
}

.code {
  background: #fafafa;
  border-radius: 8px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.normalfont {
  font-weight: normal !important;
}

.button2 {
  background-color: #0077D4;
  border: none;
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 2px 10px 2px 10px;
  border-radius: 8px;
}

.card {
  display: flex;
  flex: auto;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 2px solid #eaeaea;
  border-top: 7px solid #eaeaea;
  border-radius: 10px;
  max-width: 450px;
  width: 90%;
  margin: auto;
  display: block;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 10px;
}

.card:hover,
.card:focus,
.card:active {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card h3 {
  width: 100%;
  text-align: center;
  margin: 1rem 0 1rem 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.card p {
  margin: 0;
  font-size: 1.0rem;
  line-height: 1.5;
}

.cardimg {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  margin: 0 1em 1em 1em;
  border-radius: 8px;
}

.cardimg a{
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.cardimg img{
  max-width: 100%;
  height: auto;
  border: 0px solid #eaeaea;
  width: 65%;
  margin: auto;
  display: block;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  padding: 1em;
}

.cardimg:hover,
.cardimg:focus,
.cardimg:active{
  box-shadow: 0 4px 8px 0 rgba(0, 57, 91, 0.1);
}

.cardcontent {
  max-width: 415px;
  display: flex;
  height: 100%;
  border: 0px solid #eaeaea;
  border-radius: 8px;
  width: 90%;
  margin: auto;
  display: block;
  padding-bottom: 10px;
}

.pricebox {
  border: 0px solid #eaeaea;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  margin: 1rem 0 0 0;
}

.pricebox > .price {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1.2;
}

.pricebox > .price25plus {
  font-size: 1.00rem;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1.2;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}



.searchbar {
  color: #00395B;
  display: flex;
  max-width: 450px;
  padding: 2px 2px 2px 8px;
  border: 1px solid #CCCCCC;
  border-radius: 25px;
  width: 100%;
  margin: auto;
}

.searchbar:hover {
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
  transition: 0.3s;
}

.searchbar input[type="search"] {
  -webkit-appearance: textfield; /*Hide magnifying glass in iOS*/
  border: none;
  background: transparent;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  color: inherit;
  border: 0px solid transparent;
  border-radius: inherit;
  width: 100%;
}

.searchbar input[type="search"]::placeholder {
  color: #aaa;
}

/*Hide clear button in input box*/
.searchbar input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance:none;
}


.searchiconbutton[type="submit"] {
  color: #00395B;
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 3px;
  margin: 3px;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
  cursor: pointer;
  background-color: #F3F3F3;
  opacity: 0.45;
}

.searchiconbutton[type="submit"]:hover {
  opacity: 0.9;
}

.searchicon {
  color: #00395B;
  overflow: hidden;
  border: none;
  max-width: 25px;
  height: auto;
  margin: auto;
  padding: 0;
  vertical-align: middle;
  border-radius: inherit;
  opacity: .85;
}

.searchbutton[type="submit"] {
  color: #00395B;
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  width: 40px;
  cursor: pointer;
  border: none;
  background-color: #E0E0E0;
  border-radius: 50%;
  margin-left: 3px;
  padding: 0;
  justify-content: center;
  text-align: -webkit-center;
  opacity: .75;
}

.searchbutton[type="submit"]:hover {
  opacity: 1;
}

.resetbutton[type="reset"] {
  color: #00395B;
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  width: 40px;
  cursor: pointer;
  border: none;
  background-color: #E0E0E0;
  border-radius: 50%;
  margin-left: 3px;
  padding: 0;
  justify-content: center;
  text-align: -webkit-center;
  opacity: .75;
}

.resetbutton[type="reset"]:hover {
  opacity: 1;
}

.searchbar button[type="submit"]:focus,
.searchbar input[type="search"]:focus {
  outline: none;
  border: none;
}



.progresscontainer {
  position: relative;
  top: -1px;
  max-width: 400px;
  width: 80%;
  margin: auto;
  transition: 0.3s;
}

.progressbar {
  z-index: 9999;
  height: 0.9px;
  background-color: rgba(204, 204, 204, 1);
  overflow: hidden;
}

.progressbarvalue {
  width: 100%;
  height: 100%;
  background-color: #0077D4;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

.progressbarerrorvalue {
  width: 100%;
  height: 100%;
  background-color: #E81123;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}



/** LIGHTBOX MARKUP **/
.lightbox {
  /* Default to hidden */
  display: none;

  /* Overlay entire screen */
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  /* A bit of padding around image */
  padding: 1em;

  /* Translucent background */
  background: rgba(0, 57, 91, 0.8);
}

/* Unhide the lightbox when it's the target */
.lightbox:target {
  display: block;
}

.lightbox div {
  /* Full width and height */
  display: block;
  display: flex;
  height: 100%;
  width: 100%;
  margin: auto;

  /* Size and position background image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  /* Fade in */
  animation: fadeIn ease-in 0.25s;
  
  cursor: -moz-zoom-out; 
  cursor: -webkit-zoom-out; 
  cursor: zoom-out;
}

@keyframes fadeIn {
  from {
       opacity: 0;
  }

  to {
       opacity: 1;
  }
}

@keyframes fadeOut {
  0% { opacity: 1; height: auto; }
  90% { opacity: 0; height: auto; }
  100% { opacity: 0; height: 0;}
}

.lightbox img {
  max-width: 450px;
  height: auto;
  border: 0px solid #eaeaea;
  border-radius: 8px;
  width: 90%;
  margin: auto;
  display: block;
  padding: 0.5em;
  background: #FFFFFF;
  cursor: -moz-zoom-out; 
  cursor: -webkit-zoom-out; 
  cursor: zoom-out;
}
